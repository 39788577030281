import React, { Component, Fragment } from 'react'
import styled from '@emotion/styled'
import { rgba } from 'polished'
import Link from 'src/components/Link'
import Logo from 'src/components/Logo'
import Grid from 'src/components/Grid'
import ConditionalRender from 'src/components/ConditionalRender'
import ScrollListener from 'src/components/ScrollListener'
import NotificationBanner from 'src/components/NotificationBanner'
import ResponsiveComponent from 'src/components/ResponsiveComponent'
import AnimatedIcon from 'src/components/AnimatedIcon'
import MobileMenu from 'src/components/MobileMenu'
import { colors, typography, animations, mq, util } from 'src/styles'
import * as wndw from 'src/utils/wndw'

const showHide = false // show and hide header on scroll
const headerHeight = (additionalHeight = 0) => util.responsiveStyles('height', (100 + additionalHeight), (100 + additionalHeight), (100 + additionalHeight), (75 + additionalHeight))

const headerHeightCollapsed = util.responsiveStyles('height', 80, 80, 80, 60)

const Dropdown = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 200px;
  background: ${ colors.mainColor };
  color: ${ colors.bgColor };
  ${ typography.bodySmall };
  font-weight: 600;
  letter-spacing: 0;
  padding: 10px 16px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s ease-in-out, opacity 0.3s ease-in-out;
  a {
    display: inline-block;
    padding: 3px 0;
    opacity: .6;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      left: 100%;
      width: 5px;
      height: 5px;
      transform: rotate(-45deg);
      border-bottom: 2px solid;
      border-right: 2px solid;
      border-color: ${ colors.mainColor };
      top: 50%;
      margin-top: -3px;
      opacity: 0;
      transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    }
    &:hover {
      opacity: 1;
      &:after {
        opacity: 1;
        transform: rotate(-45deg) translate3d(5px, 5px, 0);
      }
    }
  }
`

const NavLinkStyle = (scrolled, active, hasAtf, dropdown) => `
	display: block;
	${ typography.body }
	${ util.responsiveStyles('font-size', 16, 16, 16, 11) }
	line-height: 40px;
	transition: 	padding ${ animations.mediumSpeed } ease-in-out,
								margin ${ animations.mediumSpeed } ease-in-out,
                background ${ animations.mediumSpeed } ease-in-out,
								color ${ animations.mediumSpeed } ease-in-out;
  font-weight: 600;
  width: 100%;
  height: 41px;
  postion: relative;
  &:after {
    content: '';
    position: absolute;
    display: block;
    background-color: ${colors.bgColor};
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transition: transform ${ animations.mediumSpeed } ease-in-out;
    transform: scaleY(0);
    transform-origin: center 100%;
    z-index: 0;
  }
  span {
    position: relative;
    z-index: 1;
  }
	${ active ? `
    color: ${ colors.textColor };
    &:after {
      transform: none;
    }
	` : `` }
  &:hover {
    color: ${ colors.textColor };
    &:after {
      transform: none;
    }
  }
`

const NavLink = styled(Link)`
  ${ props => NavLinkStyle(props.scrolled, props.active, props.hasAtf, props.hasDropdown) }
`

const Wrapper = styled.header`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 4;
`

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  transition: height ${ animations.mediumSpeed } ease-in-out,
    background ${ animations.mediumSpeed } ease-in-out,
    transform ${ animations.mediumSpeed } ease-in-out,
    box-shadow ${ animations.mediumSpeed } ease-in-out;

    background: transparent;
    color: ${ colors.white };
  ${ ({ scrolled, hasAtf, pageType }) =>
    scrolled
      ? `
		${ headerHeight() }
  		background: ${ colors.textColor };
  		color: ${ colors.white };
  		box-shadow: 0 1px 0 ${ rgba(colors.textColor, 0.1) };
	`
      : `
		${ headerHeight() }
		
		${
      hasAtf
        ? `
			color: ${ colors.white };
		`
        : `
			color: ${ colors.white };
		`
      }
	` }
  ${ ({ navVisible }) =>
    navVisible &&
    `
		transform: translate3d(0, -101%, 0);
	` }

`

const HeaderContent = styled(Grid)``

const HeaderLogo = styled(Logo)`
	${ util.responsiveStyles('width', 117, 117, 117, 75) }
	height: auto;
	transition: color ${ animations.mediumSpeed } ease-in-out, width ${ animations.mediumSpeed } ease-in-out;
	${ ({ scrolled, hasAtf }) => scrolled ? `
		color: ${ colors.white };
		${ util.responsiveStyles('width', 100, 100, 100, 70) }
	` : `
		${ !hasAtf ? `
			color: ${ colors.white };
		` : `
			color: ${ colors.white };
		` }
	` }
`

const HomeLogo = styled(Logo)`
  width: 204px;
  height: auto;
  margin: 0 auto;
  // transform: translateY(100%);
  animation: ${ animations.homeLogoTransform } 0.5s forwards 1;
  ${mq.smallAndBelow} {
    width: 150px;
  }
`

const LogoCol = styled.div`
	text-align: center;
	a {
		display: inline-block;
		vertical-align: top;
	}
`

const NavLinks = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  align-items: baseline;
  display: flex;
  width: 100%;
  justify-content: ${ ({ alignment }) => alignment === 'right' ? 'flex-end' : 'flex-start' };
  li {
    position: relative;
    flex-shrink: 0; 
    flex-grow: 1;
    max-width: 123px;
    height: 41px;
    display: flex;
    align-items: center;
    text-align: center;
    &:hover {
      ${ Dropdown } {
        visibility: visible;
        opacity: 1;
      }
    }
    &:not(:last-of-type) {
      ${ util.responsiveStyles('margin-right', 60, 40, 20, 20) }
    }
  }
`

const MenuIcon = styled.div`
	display: none;
	padding: 5px 10px;
	margin-left: -10px;
	cursor: pointer;
	span {
		display: block;
	}
	${ mq.mediumAndBelow } {
		display: inline-block;
	}
`

const HeaderPlaceholder = styled.div`
	background: transparent;
	width: 100%;
	transition: height 0.3s ease-in-out;
	${ ({ hasBanner }) => hasBanner ? `
		${ headerHeight(40) }
	` : `
		${ headerHeight() }
	` }
`

const HeaderNotificationBanner = styled(NotificationBanner)`
	position: relative;
	z-index: 5;
`

const EmptySpan = styled.span`
  width: 100%;
  min-width: 10px;
  min-height: 1px;
`

class Header extends Component {
  state = {
    scrolled: false,
    navList: false,
    drawerOpen: false,
    bannerVisible: true
  }

  toggleDrawer = () => {
    this.setState({ drawerOpen: !this.state.drawerOpen })
  }

  closeDrawer = () => {
    this.setState({ drawerOpen: false })
    setTimeout(() => {
      this.toggleDrawer(false)
    }, 600) // timeout needs to match the navigation drawer exit speed
  }

  closeBanner = () => {
    this.setState({ bannerVisible: false })
  }

  renderMainLogo = (pageType, scrolled, hasAtf, homeLogoScroll) => {
    if ( pageType === 'home') {
      if ( homeLogoScroll ) {
        return (
          <Link to="/">
            <HomeLogo scrolled={scrolled} hasAtf={hasAtf} />
          </Link>
        )  
      } else {
        return false
      }
    } else {
      return (
        <Link to="/">
          <HeaderLogo scrolled={scrolled} hasAtf={hasAtf} />
        </Link>
      )
    }
  }

  render () {
    const {
      location,
      hasAtf,
      bannerText,
      collapsed,
      bannerColor,
      navigation,
      pageType
    } = this.props
    const { scrolled, navList, drawerOpen, bannerVisible } = this.state
    const navLeft = navigation.slice(0,3)
    const navRight = navigation.slice(3,5)

    // console.log("HEADER",this.props)
    let pathname = '/'
    if (location) {
      pathname = location
    }

    return (
      <Fragment>
        <ScrollListener.Consumer>
          {({
            scrolledToTop,
            scrolledToBottom,
            scrollY,
            scrolledUp,
            hasScrolled,
            pageHeight,
            homeLogoScroll
          }) => {
            let scrolled = false
            if (collapsed) {
              scrolled = true
            } else {
              scrolled = !scrolledToTop && hasScrolled && !drawerOpen
            }
            return (
              <div>
                <Wrapper scrolled={scrolled} hasAtf={hasAtf} navVisible={!scrolledUp && !scrolledToTop && showHide}>
                  {bannerText && (
                    <HeaderNotificationBanner
                      closeBanner={this.closeBanner}
                      collapsed={!bannerVisible}
                      text={bannerText}
                      setTheme={bannerColor}
                    />
                  )}
                  <HeaderWrapper
                    navVisible={!scrolledUp && !scrolledToTop && showHide}
                    hasAtf={hasAtf}
                    scrolled={scrolled}
                    navVisible={!scrolledUp && !scrolledToTop && showHide}
                    pageType={pageType}

                  >
                    <HeaderContent
                      small="1 [2] [6] [2] 1"
                      medium="1 [5] [5] [5] 1"
                      large="1 [8] [8] [8] 1"
                      vAlign="center"
                      hasAtf={hasAtf}
                      scrolled={scrolled}
                      navVisible={!scrolledUp && !scrolledToTop && showHide}
                    >
                      <div>
                        <NavLinks alignment="left">
                          <ResponsiveComponent
                            small={<EmptySpan/>}
                            medium={navLeft && navLeft.map((link, index) => (
                              <li key={'header-link-' + link.id}>
                                <NavLink
                                  scrolled={scrolled}
                                  hasAtf={hasAtf}
                                  to={"/"+link.to.slug}
                                  active={pathname === link.to.slug}
                                  key={link.to.slug}
                                  hasDropdown={link.dropdownLinks}
                                >
                                  <span>{link.label}</span>
                                </NavLink>
                                {link.dropdownLinks && (
                                  <Dropdown>
                                    {link.dropdownLinks.map((dropdownLink, index) => (
                                      <div>
                                        <Link to={"/"+dropdownLink.to.slug}>{dropdownLink.label}</Link>
                                      </div>
                                    ))}
                                  </Dropdown>
                                )}
                              </li>
                            ))}
                          />
                        </NavLinks>
                      </div>
                      <LogoCol>
                      {this.renderMainLogo(pageType, scrolled, hasAtf, homeLogoScroll)}
                      </LogoCol>
                      <div>
                        <NavLinks alignment="right">
                          <ResponsiveComponent
                            small={
                              <MenuIcon id="mobile-menu-icon" onClick={() => this.toggleDrawer()}>
                                <AnimatedIcon
                                  icon={drawerOpen ? 'close' : 'menu'}
                                />
                              </MenuIcon>
                            }
                            medium={navRight && navRight.map((link, index) => (
                              <li key={'header-link-' + link.id}>
                                <NavLink
                                  scrolled={scrolled}
                                  hasAtf={hasAtf}
                                  to={"/"+link.to.slug}
                                  active={pathname === link.to.slug}
                                  key={link.to.slug}
                                  hasDropdown={link.dropdownLinks}
                                >
                                  <span>{link.label}</span>
                                </NavLink>
                                {link.dropdownLinks && (
                                  <Dropdown>
                                    {link.dropdownLinks.map((dropdownLink, index) => (
                                      <div>
                                        <Link to={"/"+dropdownLink.to.slug}>{dropdownLink.label}</Link>
                                      </div>
                                    ))}
                                  </Dropdown>
                                )}
                              </li>
                            ))}
                          />
                        </NavLinks>

                      </div>
                    </HeaderContent>
                  </HeaderWrapper>
                </Wrapper>
              </div>
            )
          }}
        </ScrollListener.Consumer>

        <ConditionalRender condition={!hasAtf}>
          <HeaderPlaceholder hasBanner={bannerText && bannerVisible} />
        </ConditionalRender>

        <ResponsiveComponent
          small={
            <MobileMenu
              open={drawerOpen}
              toggleMobileMenu={this.toggleMobileMenu}
              navLinks={navigation}
            // footerColumn1={footerColumn1}
            // footerColumn2={footerColumn2}
            />
          }
          medium={<span />}
        />

      </Fragment>
    )
  }
}

Header.defaultProps = {
  hasAtf: false
}

export default Header
